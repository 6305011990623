.bookingwrap {
  height: 100%;
  display: flex;
  /* background: url(../../assets/HomePage/destination_bg.jpg) center/cover no-repeat; */
  /* width: 100%; */
}
.mainWrapperrr{
  padding-top: 1px;
  max-width: 100%;
  /* height: 50rem; */
  position: relative;
  overflow: hidden;
  /* z-index: 0; */
  background: url(../../assets/HomePage/Frame\ 40.png) center/cover no-repeat;
}

.bookingcntnr {
  width: 50%;
  position: relative;
}
.bookingcontainer {
  /* z-index: ; */
  /* height: 43rem; */
  margin-left: 4rem;
  position: relative;
  margin-top: 4rem;
  border-radius: 20px;
  background-color: #b0c3dd;
  width: 100%;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.handImage {
  left: 37rem;
  bottom: 6rem;
  z-index: 0;
  position: absolute;
}
.bookingcontainer h1 {
  width: 90%;
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: 2rem;
}
.bookingcontainer button {
  background-color: #fe784e;
  border-radius: 10px;
  width: 9rem;
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 500;
  color: white;
  border: 0;
  height: 2.7rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition for smooth effect */
}

.bookingcontainer button:hover {
  background-color: #ff9d6e; /* New background color on hover */
}

.counsellinginputdiv {
  margin: 0.85rem 0px;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.bookingcontainer input {
  font-size: 1rem;
  font-family: Montserrat;
  padding: 0 10px;
  border-radius: 10px;
  height: 3.2rem;
  width: 19rem;
  border: 0;
}

.bookingcontainer select {
  font-size: 1rem;
  font-family: Montserrat;
  padding: 0 10px;
  border-radius: 10px;
  height: 3.2rem;
  width: 19rem;
  border: 0;
}

.counsellingbitt {
  width: 100%;
}
.counsellingbitt select {
  margin: 0.8rem 0rem;
  width: 20rem;
}
.counsellinginputdiv button {
  background-color: #030c48;
  border-radius: 10px;
  width: 2rem;
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 500;
  color: white;
  border: 0;
  height: 2rem;
}

.counsellingrightpart {
  height: 47rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 48%;
  text-align: justify;
}

.counsellingrightpart h1 {
  font-family: Montserrat;
}
.counsellingrightpart p {
  font-family: Montserrat;
  width: 75%;
  line-height: 30px;
  color: #98999d;
}
.buttondiv{
  width: 90%;
  margin-top: 0.8rem;
}
.error-input {
  border: 2px solid red !important;
}
.error-msg{
  position: absolute;
  bottom: 10%;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: Montserrat;
  color: red;
  right: 5%;
}
@media(max-width:1376px){
  .counsellingrightpart p{
    margin-left: 2rem;
    font-size: 0.85rem;
  }
  .handImage {
    left: 28.5rem;
    bottom: 9rem;
    z-index: 0;
    position: absolute;
    width: 45rem;
  }
  .bookingcontainer input {
    font-size: 1rem;
    font-family: Montserrat;
    padding: 0 10px;
    border-radius: 10px;
    height: 3.2rem;
    width: 16rem;
    border: 0;
  }
  .counsellingbitt select {
    margin: 1.1rem 0rem;
    width: 18rem;
  }
  .bookingcontainer select {
    font-size: 1rem;
    font-family: Montserrat;
    padding: 0 10px;
    border-radius: 10px;
    height: 3.2rem;
    width: 16rem;
    border: 0;
  }
}

@media (max-width: 992px) {
  .buttondiv{
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .bookingcontainer h1 {
    /* font-size: 10px; */
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
  }
  .counsellinginputdiv {
    margin: 0;
  }
  .handImage {
    display: none;
  }
  .bookingwrap {
    flex-direction: column;
  }
  .counsellinginputdiv {
    flex-direction: column;
  }
  .bookingcntnr {
    margin: 0 10px;
    width: 95%;
  }
  .counsellinginputdiv{
    width: 100%;
    display: flex;
    align-items: center;
  }
  .counsellingbitt{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .bookingcontainer {
    height: auto;
    padding: 0;
    padding-top: 5rem;
    margin-left: 0;
  }
  .bookingcontainer input ,.bookingcontainer select {
    margin: 10px 0px;
    width: 85%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .bookingcontainer .react-datepicker-wrapper input {

    margin: 10px 0;
    margin-left: 1.7rem;
    width: 85%;
  }
  
  .counsellingrightpart {
    text-align: left !important;
    margin-top: 2rem;
    width: 95%;
    height: auto;
  }
  .counsellingrightpart p {
    width: 100%;
  }
}


.counsellingwr{
    background: url(../../assets/HomePage/destination_bg.jpg) center/cover;
    margin-top: -28px;
}


@media (max-width: 480px) {
  .buttondiv{
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .bookingcontainer h1 {
    /* font-size: 10px; */
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
  }
  .counsellinginputdiv {
    margin: 0;
  }
  .handImage {
    display: none;
  }
  .bookingwrap {
    flex-direction: column;
  }
  .counsellinginputdiv {
    flex-direction: column;
  }
  .bookingcntnr {
    margin: 0 10px;
    width: 95%;
  }
  .counsellinginputdiv{
    width: 100%;
    display: flex;
    align-items: center;
  }
  .counsellingbitt{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .bookingcontainer {
    height: auto;
    padding: 0;
    padding-top: 5rem;
    margin-left: 0;
  }
  .bookingcontainer input ,.bookingcontainer select {
    margin: 10px 0px;
    width: 85%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .bookingcontainer .react-datepicker-wrapper input {

    margin: 10px 0;
    margin-left: 1.5rem;
    width: 85%;
  }
  
  .counsellingrightpart {
    text-align: left !important;
    margin-top: 2rem;
    width: 95%;
    height: auto;
  }
  .counsellingrightpart p {
    width: 100%;
  }
}


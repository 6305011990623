.mission {
  width: 100%;
  height: 37rem;
  background: url(../../assets/HomePage/footer_bg.jpg) center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission .mission-wrap {
  height: 32rem;
  width: 82%;
  display: flex;
  justify-content: center;
  /* gap: 5%; */
}
.animatedSpiral {
  position: absolute;
  width: 5rem;
  width: 36rem;
  top: 0;
  right: -1rem;
  animation-name: spin;
  animation-duration: 25s; /* Adjust the duration as needed */
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.animatedIcon {
  position: absolute;
  z-index: 5;
  width: 43rem;
  top: 0;
  right: -5rem;
  /* animation-name: spin2; */
  animation-name: shake; /* Using the shake animation */
  animation-duration: 2s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* Defining the shake animation */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateX(1px);
  }
  75% {
    transform: translateX(-1px);
  }
}

/* Defining the spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mission-wrap .image-sec {
  flex: 1;
  z-index: 1;
  position: relative;
}

.mission-wrap .image-sec .gradgirll {
  width: 105%;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: 4.5%;
}

.mission-wrap .text-sec {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

@media (max-width: 480px) {
  .mission {
    height: 60rem !important;
  }
  .mission .mission-wrap {
    width: 92%;
    height: 52rem;
  }

  .mission .mission-wrap {
    flex-direction: column-reverse;
  }
  .mission-wrap .image-sec img {
    position: relative;
    width: 23rem;
  }
  .mission-wrap .text-sec p {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .mission {
    height: 25rem;
  }
}

.RecentBlogPost {
  height: 60rem;
  width: 100%;
  background: url(../../assets/HomePage/destination_bg.jpg) center/cover
    no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RecentBlogPost .RecentBlogWrap {
  width: 80%;
  height: 90%;
  display: flex;
  gap: 3%;
  margin-top: 1.5rem;
  font-family: Montserrat;
  color: #1a093f;
}

.RecentBlogWrap .res-blog-post {
  flex: 0.7;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}

.RecentBlogWrap .res-blog-posts {
  flex: 0.35;
}

.res-blog-post .BlogPost {
  width: 95%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.res-blog-post .BlogPost .postDesc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
}

.res-blog-post .BlogPost .blogImage img {
  width: 100%;
}

.res-blog-post .BlogPost h1 {
  font-size: 2.25rem;
}

.res-blog-post .BlogPost .blog-info {
  display: flex;
  align-items: center;
}

.BlogPost .blog-info .blog-date {
  margin-left: auto;
}

.BlogPost .blog-info .author-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.BlogPost .blog-info .author-info .author-img {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: black;
}

.res-blog-post .BlogPost::-webkit-scrollbar {
  display: none;
}

.res-blog-post .BlogPost .blogImage img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.res-blog-posts .RecentBlogs {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
}

.res-blog-posts .RecentBlogs h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.RecentBlogs .rec-blog-card {
  display: flex;
  margin-top: 1rem;
  gap: 0.8rem;
  align-items: flex-start;
}

.RecentBlogs .rec-blog-card .img {
  width: 40%;
}

.RecentBlogs .rec-blog-card .img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.RecentBlogs .rec-blog-card .blog-text {
  width: 60%;
}

.rec-blog-card .blog-text h5 {
  font-size: 1.12rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.rec-blog-card .blog-text p {
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 480px) {
  .RecentBlogPost {
    height: 39rem;
  }
  .RecentBlogPost .RecentBlogWrap {
    width: 95%;
    height: 93%;
    gap: 1.5%;
  }
  .RecentBlogWrap .res-blog-post {
    flex: 0.75;
    padding-top: 0.5rem;
  }

  .res-blog-post .BlogPost {
    width: 97%;
    gap: 0.6rem;
  }
  .BlogPost .blog-info .author-info,
  .blog-date {
    font-size: 0.6rem;
  }
  .res-blog-post .BlogPost h1 {
    font-size: 1.5rem;
  }

  .res-blog-post .BlogPost .postDesc {
    font-size: 0.8rem;
    line-height: 1.3rem;
    width: 95%;
    margin: 0 auto;
  }

  .RecentBlogWrap .res-blog-posts {
    flex: 0.3;
  }

  .res-blog-posts .RecentBlogs h3 {
    font-size: 0.7rem;
  }
  .RecentBlogs .rec-blog-card {
    flex-direction: column;
    margin-top: 0.7rem;
    gap: 0.2rem;
  }
  .RecentBlogs .rec-blog-card .img {
    width: 100%;
  }
  .RecentBlogs .rec-blog-card .blog-text {
    width: 100%;
  }
  .rec-blog-card .blog-text h5 {
    font-size: 0.7rem;
    line-height: 1rem;
  }
  .rec-blog-card .blog-text p {
    font-size: 0.6rem;
    text-align: start;
  }
}

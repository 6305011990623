.country_head_wrap {
  padding-top: 1px;
  max-width: 100%;
  height: 50rem;
  position: relative;
  background: url(../../assets/HomePage/Frame\ 40.png) center/cover no-repeat;
  overflow: hidden;
}

.country_hero_section {
  height: 30.7rem;
}

.country_hero_section .country_search {
  width: 24.8rem;
  font-family: Montserrat;
  position: absolute;
  top: 43%;
  left: 15%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.country_hero_section .countryMainImage {
  position: absolute;
  top: 48%;
  width: 80%;
  left: 37.5%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.country_hero_section .countryMainImage .countryImg {
  width: 100%;
}

.countryMainImage .course_panel {
  position: absolute;
  transform: translate(0%, -50%);
  bottom: -20%;
  left: 20.5%;
}

.country_search .abroad_title {
  margin-bottom: 0.5rem;
}

.country_hero_section .country_search_curv {
  height: 4.8rem;
  border-radius: 75px;
  background: #fff;
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 1rem 0 3rem;
}
.country_search_curv h3 {
  font-weight: 700;
  font-size: 2.5rem;
}

.country_search_curv .search_part {
  margin-left: auto;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  background: transparent;
}

@media (max-width: 1373px) {
  .country_hero_section .countryMainImage {
    left: 25.5%;
  }
  .countryMainImage .course_panel {
    left: 30.5%;
  }
}
@media (max-width: 992px) {
  .country_hero_section .country_search{
    left: 28%;
    width: 27rem;
  }
  .country_search_curv h3 {
    font-weight: 700;
    font-size: 2rem;
}
.country_hero_section .countryMainImage{
  left: 7.5%;
}
  .countryMainImage .course_panel {
    left: 65.5%;
    bottom: -5%;
}

}
@media (max-width: 480px) {
  .country_head_wrap {
    height: 58rem;
  }

  .country_hero_section .countryMainImage {
    left: 35.5%;
    top: 78%;
    width: 63rem;
  }

  .country_hero_section .countryMainImage .country-main {
    width: 100%;
  }

  .country_hero_section .country_search {
    top: 18%;
    width: 97%;
    left: 50%;
  }

  .country_hero_section .country_search_curv {
    height: 4.3rem;
    padding: 0 1rem 0 2rem;
  }

  .country_search_curv h3 {
    font-size: 2rem;
  }
  .countryMainImage .course_panel {
    transform: translate(-50%, -50%);
    left: 55%;
    bottom: -24%;
  }
}

.test-prep-image {
  max-width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 100%;
}
.test-prep-image-div {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 992px) {
    .test-prep-image-div{
        display: none;
    }
  .testprepimg {
    width: 100%;
    display: block;
  }
  .mobileimagetest {
    margin: 5% 0;
    width: 75% !important;
    margin-left: 0 !important;
  }
  .desco{
    font-size: 1rem;
  }
}

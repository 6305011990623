.blog_head_wrap {
  padding-top: 1px;
  max-width: 100%;
  height: 55rem;
  position: relative;
  background: url(../../assets/HomePage/Frame\ 40.png) center/cover no-repeat;
  overflow: hidden;
}

.blog_hero_section {
  height: 30.7rem;
}

.blog_hero_section .blog_search {
  width: 28.8rem;
  font-family: Montserrat;
  position: absolute;
  top: 43%;
  left: 23%;
  transform: translate(-50%, -50%);
  z-index: 3;
}


.blog_hero_section .bgCircle {
  position: absolute;
  right: 5%;
  bottom: -13%;
  width: 40%;
}

.blog_hero_section .blogMainImage {
  position: absolute;
  top: 55%;
  width: 70%;
  left: 55.5%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.blog_hero_section .blogMainImage .blog-main {
  width: 100%;
  border-radius: 20px;
}

.blogMainImage .cloud {
  position: absolute;
  bottom: 0%;
  right: -10%;
}
.blogMainImage .course_panel {
  position: absolute;
  transform: translate(0%, -50%);
  bottom: -17%;
  left: 1%;
}

.blog_hero_section .launchRocket {
  position: absolute;
  right: 83%;
  top: 10%;
  width: 3.5rem;
}

.blog_hero_section .launchRocket {
  position: absolute;
  right: 80%;
  top: 0%;
  width: 3.5rem;
}
.blog_hero_section .smallLaunchRocket {
  position: absolute;
  right: 5%;
  top: 55%;
  width: 1.8rem;
}

.blog_hero_section .book {
  position: absolute;
  right: 1%;
  width: 7rem;
  bottom: 8%;
}

.blog_hero_section .bookShelf {
  position: absolute;
  right: 68%;
  top: 18%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #6347eb;
  opacity: 0.7;
}

.blog_search .abroad_title {
  margin-bottom: 0.5rem;
}

.blog_hero_section .blog_search_curv {
  height: 4.8rem;
  border-radius: 75px;
  background: #fff;
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 1rem 0 3rem;
}
.blog_search_curv h3 {
  font-weight: 700;
  font-size: 3rem;
}

.blog_search_curv .search_part {
  margin-left: auto;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  background: transparent;
}

@media (max-width: 1373px) {
  .blog_hero_section .blogMainImage {
    left: 50.5%;
  }

  .blogMainImage .course_panel {
    bottom: -18%;
    left: -10%;
  }
}
@media (max-width: 992px) {
  .blogMainImage .course_panel{
    left: 25% !important;
  }
  .blog_hero_section .blogMainImage{
    top: 30% !important;
    width: 130% !important;
  }
  .blog_hero_section .blog_search{
    left: 28% !important;
  }
}
@media (max-width: 480px) {
  .blog_head_wrap {
    height: 58rem;
  }

  .blog_hero_section .blogMainImage {
    left: 64.5%;
    top: 80% !important;
    width: 40rem !important;
  }

  .blog_hero_section .blogMainImage .blog-main {
    width: 100%;
  }

  .blog_hero_section .bgCircle {
    width: 22rem;
    bottom: -10%;
    right: -5%;
  }

  .blog_hero_section .bgCircle .bg-circle {
    width: 100%;
  }

  .blog_hero_section .blog_search {
    top: 18%;
    width: 97%;
    left: 50% !important;
  }

  .blog_hero_section .blog_search_curv {
    height: 4.3rem;
    padding: 0 1rem 0 2rem;
  }

  .blog_search_curv h3 {
    font-size: 2rem;
  }

  .blogMainImage .course_panel {
    transform: translate(-50%, -50%);
    left: 42% !important;
  }

  
}

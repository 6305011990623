/* font-family: "Montserrat", sans-serif;
font-family: "Playfair Display", serif;
font-family: "Roboto", sans-serif; */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-inline: hidden; */
}

.center {
  margin: 0 auto;
}

/* .testBorder {
  border: 2px solid rgba(255, 0, 0, 0.169);
  outline: 2px solid rgba(255, 0, 0, 0.169);
} */

.mainWrapper {
  background: #f3f6f9;
  position: relative;
  z-index: 0;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
}

li a {
  text-decoration: none;
}

.react-datepicker__input-container .date_input {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(214, 226, 232);
  background: linear-gradient(
    90deg,
    rgba(214, 226, 232, 1) D6E2E 8%,
    rgba(243, 246, 249, 1) 100%
  );
}

::-webkit-scrollbar-thumb {
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Color and transparency of the scrollbar thumb */
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);

  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Color and transparency of the scrollbar thumb on hover */
}

.testimonials-sec {
  height: 55rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/HomePage/overseasBg.jpg) center/cover no-repeat;
  position: relative;
}

.testimonials-sec .testimonials-wrap {
  width: 80%;
  height: 45rem;
  display: flex;
  flex-direction: column;
}

.testimonials-wrap .test-slider-part {
  margin-top: auto;
}

.testimonials-wrap .test-head-part {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Montserrat;
}

.test-head-part .test-title {
  color: #fb8f2a;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.test-head-part .test-head {
  color: #1a093f;
  font-size: 3rem;
  font-weight: 900;
  line-height: 4.5rem;
}

.test-head-part .test-desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8rem;
  width: 60%;
  text-align: center;
}

.testi-slid .test-slider-con,
.test-slider-con .msgSlideWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  text-align: center;
}

.services-swiper{
  height: auto;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.testi-slid .test-slider-con {
  gap: 0.5rem;
  opacity: 0.2;
  margin-top: 2.2rem;
  transition: 1s ease;
}
.swiper-slide{
  /* height: 90%; */
}

.testi-slid .swiper-slide-next {
  filter: drop-shadow(-2px 9px 36px #00000033);
  opacity: 1;
  margin-top: 0;
}

.test-slider-con .msgSlideWrap {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem 1rem 1rem;
  margin-bottom: 1.3rem;
  position: relative;
  /* box-shadow: 2px 5px 11px rgba(0, 0, 0, 0.31); */
}

.test-slider-con .msgSlideWrap::after {
  position: absolute;
  content: "";
  border-top: 1.5rem solid #fff;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  bottom: -1.2rem;
  filter: drop-shadow(-2px 9px 36px #00000025);
}

.test-slider-con h3 {
  color: #18191f;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: capitalize;
  padding-bottom: 0.3rem;
}

.test-slider-con p {
  color: #31333e;
  font-size: 0.87rem;
  font-weight: 400;
  line-height: 1.87rem;
}

.test-slider-con img {
  width: 3.5rem;
}

.test-slider-con h4 {
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.8rem;
}

.test-slider-con h6 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #474a57;
}

.test-slider-part .test-slider-controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.test-slider-controls .testi-button-prev1,
.testi-button-next1 {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1rem;
  cursor: pointer;
}
@media (max-width: 992px) {
  .testimonials-wrap .test-slider-part{
    margin-top: 3rem;
  }
  .test-head-part .test-desc{
    width: 95%;
  }
  .msgSlideWrap {
    width: 20rem;
  }
  .testimonials-sec .testimonials-wrap {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 480px) {
  .testimonials-sec {
    height: 53rem;
  }
  .testimonials-sec .testimonials-wrap {
    height: 48rem;
    width: 90%;
  }

  .test-head-part .test-title {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .test-head-part .test-head {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }

  .test-head-part .test-desc {
    width: 95%;
  }

  .testi-slid .test-slider-con {
    opacity: 1;
    margin-top: 0rem;
  }
}

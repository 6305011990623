.subscription {
  height: 33.18rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/HomePage/destination_bg.jpg) center/cover
    no-repeat;
  z-index: 0;
  position: relative;
}

.subscription .subs-wrap {
  width: 80%;
  height: 26.4rem;
  align-self: flex-end;
  background: rgba(153, 169, 212, 0.5);
  border-radius: 129px 20px 20px 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5%;
  gap: 4%;
}

.subs-wrap .title {
  color: #fb8f2a;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.subs-wrap h1 {
  color: #1a093f;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 900;
  line-height: 70px; /* 145.833% */
  text-transform: capitalize;
}

.subs-wrap .description {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  max-width: 28rem;
}

.subs-wrap form {
  max-width: 39.1rem;
  height: 4.25rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 4%;
}

.subs-wrap .input-container input {
  width: 26.3rem;
  height: 4.25rem;
  border-radius: 10px;
  padding: 0 0 0 3.5rem;
  border: none;
  outline: none;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Montserrat;
}

.subscribe-form .input-container {
  position: relative;
}

.subscribe-form .subMsgIcon {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 1.3rem;
  transform: translate(0%, -50%);
}

.subs-wrap .subscribe-form button {
  cursor: pointer;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.06rem;
  border: none;
  width: 11.25rem;
  height: 4.25rem;
  border-radius: 10px;
  background: linear-gradient(180deg, #ff946d 0%, #ff7d68 100%);
}

.subs-wrap .topIcon,
.bottomIcon {
  width: 4.3rem;
}

.subs-wrap .topIcon {
  position: absolute;
  top: -5%;
  right: -2%;
}
.subs-wrap .bottomIcon {
  position: absolute;
  bottom: -5%;
  left: -2%;
}

.subs-wrap .bottomSpiral {
  opacity: 0.2;
  width: 24.3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.subs-wrap .topSpiral {
  width: 16.5rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media(max-width:992px) {
  .subscription{
    height: 25rem;
  }
  .subs-wrap h1{
    line-height: 55px;
    font-size: 2.5rem;
  }
  .subscription .subs-wrap{
    width: 92%;
    height: 21.4rem;
  }
}

@media (max-width: 480px) {
  .subscription {
    height: 21.5rem;
  }

  .subs-wrap .topIcon,
  .bottomIcon {
    display: none;
  }

  .subs-wrap .bottomSpiral,
  .topSpiral {
    display: none;
  }
  .subscription .subs-wrap {
    width: 93%;
    justify-content: center;
    height: 18.4rem;
    border-radius: 50px 20px 20px 20px;
    gap: 2%;
  }

  .subs-wrap .title {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  .subs-wrap .description {
    font-size: 0.85rem;
    line-height: 1.2rem;
    max-width: 83%;
  }

  .subs-wrap h1 {
    font-size: 1.25rem;
    line-height: 2rem; /* 145.833% */
  }

  .subs-wrap form {
    max-width: 93%;
    gap: 1rem;
    margin-top: 6%;
    height: 4.25rem;
  }

  .subs-wrap .input-container input {
    width: 100%;
    height: 3rem;
  }

  .subs-wrap .subscribe-form button {
    width: 30%;
    height: 3rem;
    font-size: 0.9rem;
  }
}

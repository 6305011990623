/* Modal.css */

.modal-overlay {
  /* ... (existing styles) */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Hide the modal initially */
}

.modal-overlay.active {
  opacity: 1;
  pointer-events: auto; /* Allow interactions when modal is active */
}
.modal-overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    text-align: center;
    background-color: #fff;
    padding: 2rem;
    background: linear-gradient(180deg, rgba(46, 46, 46, 0.00) 0%, rgba(255, 255, 255,1) 0.01%, #CBE2EB 100%);
    width: 50%;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .modal-content h3{
    font-size: 2rem;
  }
  .modal-content p{
    width: 100%;
    line-height: 1.5rem;
    text-align: justify;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 10px;
    cursor: pointer;
  }
  .modal-close img{
    width: 2rem;
    height: 2rem;
  }
  
  .modal-body {
    padding: 10px;
  }
  
  @media(max-width:480px){
    .modal-content{
        width: 95%;
    }
  }
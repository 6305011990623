.contact_head_wrap {
  padding-top: 1px;
  max-width: 100%;
  height: 48rem;
  position: relative;
  background: url(../../assets/HomePage/Frame\ 40.png) center/cover no-repeat;
  overflow: hidden;
}

.contact_hero_section {
  height: 30.7rem;
}

.contact_hero_section .contact_search {
  width: 24rem;
  font-family: Montserrat;
  position: absolute;
  top: 43%;
  left: 13%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.contact_hero_section .bgCircle {
  position: absolute;
  right: 5%;
  bottom: -13%;
  width: 40%;
}

.contact_hero_section .contactMainImage {
  position: absolute;
  top: 45%;
  width: 75%;
  left: 49.5%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.contactMainImage .cloud {
  position: absolute;
  bottom: -8%;
  right: -22%;
}
.contactMainImage .course_panel {
  position: absolute;
  transform: translate(0%, -50%);
  bottom: -6%;
  left: 4.5%;
}

.contact_hero_section .launchRocket {
  position: absolute;
  right: 83%;
  top: 10%;
  width: 3.5rem;
}

.contact_hero_section .launchRocket {
  position: absolute;
  right: 80%;
  top: 0%;
  width: 3.5rem;
}
.contact_hero_section .smallLaunchRocket {
  position: absolute;
  right: 5%;
  top: 55%;
  width: 1.8rem;
}

.contact_hero_section .book {
  position: absolute;
  right: 1%;
  width: 7rem;
  bottom: 8%;
}

.contact_hero_section .bookShelf {
  position: absolute;
  right: 68%;
  top: 18%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #6347eb;
  opacity: 0.7;
}

.contact_search .abroad_title {
  margin-bottom: 0.5rem;
}

.contact_hero_section .contact_search_curv {
  height: 4.8rem;
  border-radius: 75px;
  background: #fff;
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 1rem 0 3rem;
}
.contact_search_curv h3 {
  font-weight: 700;
  font-size: 3rem;
}

.contact_search_curv .search_part {
  margin-left: auto;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  background: transparent;
}

@media (max-width: 1373px) {
  .contact_hero_section .contactMainImage {
    left: 40.5%;
  }
}
@media (max-width:992px) {
 
  .contactMainImage .course_panel{
    bottom: 4%;
    left: 50%;
  }
  .contact_hero_section .contactMainImage{
    left: 16.5%;
  }
  .contact_search_curv h3{
    font-size: 2rem;
  }
  .contact_hero_section .contact_search{
    width: 25rem;
    left: 26%;
  }
  .contact_head_wrap{
    height: 50rem;
  }
}
@media (max-width: 480px) {
  .contact_head_wrap {
    height: 58rem;
  }

  .contact_hero_section .contactMainImage {
    left: 25.5%;
    top: 80%;
    width: 40rem;
  }
  .contact_hero_section .contactMainImage .contact-main {
    width: 100%;
  }

  .contact_hero_section .bgCircle {
    width: 22rem;
    bottom: -9%;
    right: -2%;
  }
  .contact_hero_section .bgCircle .bg-circle {
    width: 100%;
  }

  .contact_hero_section .contact_search {
    top: 18%;
    width: 97%;
    left: 50%;
  }
  .contact_hero_section .contact_search_curv {
    height: 4.3rem;
    padding: 0 1rem 0 2rem;
  }

  .contact_search_curv h3 {
    font-size: 2rem;
  }

  .contactMainImage .course_panel {
    transform: translate(-50%, -50%);
    left: 63.3%;
    bottom: -21%;
  }
}

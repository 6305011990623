footer {
  height: 32.64rem;
  width: 100%;
  background: center/cover no-repeat url("../../assets/HomePage/footer_bg.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer .footer-container {
  width: 88%;
  height: 23rem;
  display: grid;
  grid-template-columns: 1.6fr repeat(3, 1fr);
  position: relative;
}
.logo .subsidiary{
  font-family: Montserrat;
  /* margin-bottom: 1rem; */
  width: 65%;
  text-align: left;
  color: #536288;
}
@media(max-width:480px){
  .logo .subsidiary{
    margin-bottom: 1rem;
    font-size: 0.9rem;
    width: 75%;
    text-align: left;
  }
}
.logo .holdingsdiv{
  width: 69%;
  font-family: Montserrat;
  display: flex;
  /* flex-direction: row; */
  align-items:start ;
}


.logo .holdingsdiv p{
  /* top: 0; */
  margin-left: 5%;
  margin-bottom: 3%;
  /* position: absolute; */
  font-size: 0.8rem;
}

.logo .holdingsdiv img{
  width: 18rem;
}
.footer-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-container .logo {
  margin-bottom: 3%;
}

.logo .contint_logo {
  width: 85%;
}

@media(max-width:480px){
  .logo .holdingsdiv img{
    width: 10rem;
  }
  .logo .holdingsdiv{
    width: 70%;
  }
  .logo .holdingsdiv p{
    /* top: 0; */
    margin-bottom: 3%;
    margin-left: 5%;
    /* position: absolute; */
    font-size: 0.6rem;
  }  
}
.logo p {
  margin-top: 2%;
  text-align: start;
}

.logo .info_link,
.phone {
  color: #0f2f64;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.24rem;
  align-self: flex-start;
  margin-left: 17%;
}

.logo .follow_us {
  margin-top: 2%;
  color: #1a093f;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3%;
}

.follow_us h4 {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-left: 17%;
}

.footer-container .company,
.resources,
.support {
  gap: 3%;
}

.footer-container .company h1,
.resources h1,
.support h1 {
  color: #0f2f64;
  font-weight: 700;
  font-size: 1.39rem;
  font-family: "Montserrat", sans-serif;
}

.footer-container .company a,
.resources a,
.support a {
  font-size: 1.39rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #536288;
}

.company a {
  align-self: flex-start;
  margin-left: 33%;
}

.footer-container .resources,
.support {
  margin-bottom: 10.2%;
}

.resources a {
  margin-left: 32%;
  align-self: flex-start;
}

.support a {
  margin-left: 32%;
  align-self: flex-start;
}

.support h1 {
  margin-right: 6%;
}

.footer-container::before,
.footer-container::after {
  content: "";
  position: absolute;
  left: -2%;
  right: 0;
  width: 104%;
  height: 2px;
  background: url("../../assets/HomePage/navbar_line.png");
}

.footer-container::before {
  top: 2%;
}
.footer-container::after {
  bottom: 2%;
}

footer .credits {
  display: flex;
  width: 100%;
  height: 1rem;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 0.6%; */
}

.credits p {
  color: #536288;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1373px) {
  footer .footer-container {
    width: 90%;
    grid-template-columns: 1.4fr repeat(3, 1fr);
  }

  .footer-container .logo {
    margin-bottom: 0;
    align-items: start;
  }

  .logo .info_link,
  .phone,
  .follow_us h4 {
    margin-left: 5%;
  }

  .support a {
    margin-left: 12%;
  }

  .support h1 {
    margin-right: 34%;
  }
}
@media (max-width: 992px){
  .support h1{
    font-size: 1.1rem;
  }
  .resources h1{
    font-size: 1.1rem
  }
  .logo .info_link, .phone{
    font-size: 1rem;
  }
  .footer-container .company h1{
    font-size: 1.1rem;
  }
  .footer-container a{
    font-size: 1rem;
  }
  .footer-container .company p{
    font-size: 1rem;

  }
  .footer-container .company a{
    font-size: 1rem;
  }
  footer .footer-container{
    width: 98%;
  }
  .footer-container .logo{
    width: 70%;
  }
}
@media (max-width: 480px) {
  .footer-container .company {
    display: none;
  }
  .footer-container .resources {
    display: none;
  }
  .footer-container .support {
    display: none;
  }
  footer {
    height: 25.64rem;
  }

  footer .footer-container {
    height: 19rem;
  }

  .logo .info_link,
  .phone {
    font-size: 0.97rem;
  }
  footer .credits {
    bottom: 0.25%;
  }
  .credits p {
    font-size: 0.87rem;
  }
}

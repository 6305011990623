.ContactDetails {
  height: 51rem;
  width: 100%;
  background: url(../../assets/HomePage/destination_bg.jpg) center/cover
    no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactDetails .contacts-wrap {
  width: 88%;
  height: 90%;
}

.contacts-wrap .contacts-text-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: Montserrat;
}

.contacts-text-sec h3 {
  color: #fb8f2a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.contacts-text-sec h1 {
  color: #1a093f;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3.5rem;
  text-transform: capitalize;
}

.contacts-text-sec p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  max-width: 40%;
  text-align: center;
}

.contacts-wrap .contact-image-btns {
  display: flex;
}

.contact-image-btns .contact-Img,
.contact-details {
  flex: 1;
}

.contact-image-btns .contact-details {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  justify-content: center;
  gap: 2rem;
}

.contact-details .contact_cards {
  width: 93%;
  border-radius: 18.259px;
  background: #fff;
  box-shadow: 0px 18.2586669921875px 73.03466796875px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2.2rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-left: 2.8rem;
}

.contact_cards .text-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact_cards img {
  width: 4.5rem;
  height: 4.5rem;
}

.contact_cards .text-details h3 {
  font-size: 1.36rem;
  font-weight: 700;
}

.contact_cards .text-details p {
  color: #8d8d8d;
  font-weight: 500;
  font-size: 1.36rem;
}

.contact-image-btns .contact-Img img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 992px){
  .ContactDetails .contacts-wrap{
    width: 100%;
  }
  .ContactDetails{
    height: 40rem;
  }
 .contact-details{
  width: 50%;
 }
 .contact-details .contact_cards{
  width: 100%;
  margin-left: -1rem;
  padding: 1rem 0.5rem
 }
 .contact_cards .text-details p{
  font-size: 1rem;
 }
 .contact_cards img {
  width: 2.5rem;
  height: 2.5rem;
}
}

@media (max-width: 480px) {
  .ContactDetails {
    height: 53rem;
  }

  .ContactDetails .contacts-wrap {
    width: 95%;
  }

  .contacts-text-sec h3 {
    font-size: 0.8rem;
  }
  .contacts-wrap .contacts-text-sec {
    gap: 1rem;
  }

  .contacts-text-sec p {
    max-width: 90%;
    line-height: 1.7rem;
  }

  .contacts-text-sec h1 {
    font-size: 2rem;
    line-height: 1.1rem;
  }

  .contacts-wrap .contact-image-btns {
    flex-direction: column;
  }
  .contact-image-btns .contact-details {
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
  }
  .contact-image-btns .contact-Img {
    margin-top: 1rem;
  }
  .contact-details .contact_cards {
    margin-left: 0;
    width: 100%;
    padding: 0.7rem 0.5rem;
    gap: 0.7rem;
  }

  .contact_cards .text-details {
    gap: 0.2rem;
  }
  .contact_cards img {
    width: 3rem;
    height: 3rem;
  }

  .contact_cards .text-details h3 {
    font-size: 1rem;
  }

  .contact_cards .text-details p {
    font-size: 0.84rem;
  }
}
